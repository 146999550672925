import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { mediaQuery } from "../../components/_breakpoints";
import Article from "../Article";
import Content, { HTMLContent } from "../../components/Content";
import ArticlesHideShow from "../../components/BlogRoll";

const ArticlesRoll = ({ data }) => {
  const [isArticlesOpen, setArticlesOpen] = useState(false);
  const { edges: posts } = data.allMarkdownRemark;

  const handleShowHideButton = () => {
    setArticlesOpen((prev) => !prev);
  };

  return (
    <ArticlesRollWrapper>
      <NewestArticleWrapper
        index={posts.length}
        isArticlesOpen={isArticlesOpen}
      >
        <Article
          title={posts[0].node.frontmatter.title}
          description={posts[0].node.frontmatter.description}
          content={posts[0].node.html}
          date={posts[0].node.frontmatter.date}
          PostContent={HTMLContent || Content}
        />
      </NewestArticleWrapper>
      <ArticlesHideShow
        onShowHideButton={handleShowHideButton}
        isArticlesOpen={isArticlesOpen}
        isArticleRoll={true}
      />
    </ArticlesRollWrapper>
  );
};

const NewestArticleWrapper = styled.div`
  display: flex;
  width: 100%;
  ${(props) =>
    props.isArticlesOpen ? `margin-bottom: 250px` : `margin-bottom: 0`};
}
  ${(props) =>
    mediaQuery.sm(`
      min-height: 40vh;
      width: 100%;
      position: relative;
      top: -28px;
     &:before {
      content: '#${props.index}';
      font-size: 55px;
      margin-right: 0;
      top: 10px;
      display: flex;
      position: relative;
    }
  `)}
`;

const ArticlesRollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  ${mediaQuery.sm(`
    margin-left: 100px;
    margin-top: 100px;
    justify-content: flex-end;
  `)};
`;

ArticlesRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query FirstArticleQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "article-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              html
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "DD.MM.YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ArticlesRoll data={data} count={count} />}
  />
);
